import GaugeDashboard from '../icons/GaugeDashboard';
import { ServiceItemType } from '../types';

export default {
    category: 'optimize',
    displayName: 'Device checkups',
    IconComponent: GaugeDashboard,
    targetUrl:
        'www.asurion.com/dashboard/devices/select?devicetype=phone&cid=dashboard_services_menu',
    actionId: 'GoToDeviceCare',
    order: 1,
} as ServiceItemType;
